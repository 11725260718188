import React, { FC, ReactNode, useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Pagination,
  Accordion,
  Button,
  Navbar,
  Nav,
} from "react-bootstrap";
import { makeStyles } from "@material-ui/core";
import { AxiosResponse } from "axios";
import moment from "moment";
import { useDebounce } from "use-debounce";
import { Theme } from "../../theme";
import { API_URL } from "../../constants";
import { UploadFile } from "../../types/uploadFile";
import axiosInstance from "../../utils/axios";
import { isValidEmail } from '../../utils/validators';
import Page from "../../components/Page";
import ContentBuilder, {
  ContentBuilderProps,
} from "../../views/cms/AnglissView/ContentBuilder";
import Spinner from "../../components/common/Spinner";
import Checkbox from "@material-ui/core/Checkbox";
import { ImageWithFrameProps } from "../../components/common/ImageWithFrame";
import ImageWithFrameAndText, { ImageWithFrameAndTextProps } from "../../components/common/ImageWithFrameAndText";
import useLanguage from "../../hooks/useLanguage";

// data_grid view
import _ from 'lodash';
import { GridRowData, GridRowParams, GridSelectionModel, MuiEvent } from '@material-ui/data-grid';
import SearchBar, { SearchEvent } from '../../components/navigation/searches/SearchBar';
import Product from '../../types/Product';
// import CatOne from '../../types/CatOne';
import BrandType from '../../types/Brand';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import ProductCategoryBuilder, { ProductCategoryGroup, ProductCategory } from "../../components/angliss/product/ProductCategoryBuilder";
import ProductDataGrid, { Product as ProductDataGridProduct } from '../../components/angliss/product/ProductDataGrid';
import ProductModal from '../../components/angliss/product/ProductModal';
import ProductPreviewButton from '../../components/angliss/product/ProductPreviewButton';
import ProductPdfPreviewModal from "../../components/angliss/product/ProductPdfPreviewModal";
import ProductContactModal, { ContactInformation } from '../../components/angliss/product/ProductContactModal';
import ProductPdfDownloadModal from "../../components/angliss/product/ProductPdfDownloadModal";
import Highlight, { HighlightSlide } from '../../components/angliss/product/Highlight';
import HighlightSlideModal from "../../components/angliss/product/HighlightSlideModal";

export type DisplayType =
  | "text_with_side_images"
  | "gallery"
  | "images"
  | "text_with_background"
  | "data_grid";

export interface Category {
  id?: string;
  name?: string;
  slug?: string;
}

export interface PostProps {
  id?: string;
  title?: string;
  excerpt?: string;
  cover?: UploadFile;
  logo?: UploadFile;
  description?: string;
  alignment?: string;
  category?: Category;
  disable_detail_view?: boolean;
  content?: ContentBuilderProps[];
  created_at?: Date;
  updated_at?: Date;
  published_at?: Date;
  code?: string,
}

export interface YearFilter {
  enable: boolean;
}

export interface CategoryFilter {
  id: string;
  name: string;
  enable: boolean;
  multi_select: boolean;
  categories: Category[];
}

export interface GalleryWithOption {
  items_per_row: number;
  item_width: string;
  item_height: string;
}

export interface PostViewProps {
  id: number;
  category?: any;
  category_filter?: [CategoryFilter];
  posts?: any;
  display?: DisplayType;
  items_per_page?: number;
  year_filter?: YearFilter;
  enable_search?: boolean;
  gallery_options?: GalleryWithOption;
  categoriesProp?: any;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
  },
  row: {
    // width: "100%",
    flex: 1,
  },
  postContainer: {
    paddingTop: '2rem',
    display: "flex",
  },
  searchBar: {
    marginBottom: "10px",
  },
  categoryFilterItem: {
    padding: "10px",
    "&:hover, &.active": {
      color: "white",
      backgroundColor: theme.palette.primary.main,
    },
  },
  pagingLink: {
    marginTop: '1rem',
    "&>.page-item>a.page-link": {
      color: theme.palette.primary.main,
    },
  },
  activePage: {
    "&>span": {
      backgroundColor: `${theme.palette.primary.main} !important`,
      borderColor: `${theme.palette.primary.main} !important`,
    },
  },
  accordionToggle: {
    color: theme.palette.primary.main,
  },
  postLink: {
    color: "inherit",
    "&:hover": {
      color: theme.palette.primary.main,
      textDecoration: "none",
    },
  },
  yearFilter: {
    fontSize: "max(24px, calc(3em))",
    color: theme.palette.text.primary,
    "&:hover": {
      textDecoration: "none",
      color: theme.palette.primary.main,
    },
  },
  activeYear: {
    fontSize: "max(24px, calc(3em))",
    color: theme.palette.text.primary,
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    "&:hover": {
      textDecoration: "none",
      color: theme.palette.primary.main,
    },
  },
  galleryCol: {
    marginBottom: theme.spacing(4),
  },
  categoryCol: {
    marginBottom: theme.spacing(4),
    '&:hover': {
      cursor: 'pointer',
    },
  },
  breadcumbs: {
    marginTop: "2rem",
    marginBottom: "2rem",
  },
  dataGrid: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '3rem',
  },
  brandCol: {
    boxShadow: '1px 0 0 0 #c4c4c4, 0 1px 0 0 #c4c4c4, 1px 1px 0 0 #c4c4c4, inset 1px 0 0 0 #c4c4c4, inset 0 1px 0 0 #c4c4c4',
    marginBottom: 0,
    '&:hover': {
      boxShadow: '0 15px 30px rgb(0 0 0 / 10%)',
      transform: 'translate3d(0,-2px,0)',
      transition: 'all .2s linear',
      cursor: 'pointer',
      background: '#fff',
    },
    '@media (max-width: 767px)': {
      padding: 15,
    }
  },
  imageWithFrame: {
    backgroundSize: 'contain !important',
    '@media (max-width: 767px)': {
      height: '8rem !important',
    }
  },
  navbar: {
    margin: '0 -15px',
    padding: 0,
    overflow: 'auto',
    '& .navbar-nav': {
      width: '100%',
      '& .nav-link': {
        width: '100%',
        padding: 0,
      }
    },
  },
  navWrapper: {
    maxHeight: 500,
    overflow: 'auto',
  },
  eventExcerptStyle: {
    fontSize: '1.5rem',
  },
}));

const Posts: FC<PostViewProps> = ({
  category,
  posts,
  display,
  items_per_page,
  year_filter,
  category_filter,
  enable_search,
  gallery_options,
  categoriesProp
}) => {
  const classes = useStyles();
  const { lang = 'en' } = useLanguage();
  const [postsObject, setPostsObject] = useState<any>([]);
  const [pageIndex, setPageIndex] = useState<number>(0);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [keyword] = useDebounce(searchKeyword, 1500);
  const [selectedCategories, setSelectedCategories] = useState<number[]>([]); // general selected category
  const [defaultRecipeCatId, setDefaultRecipeCatId] = useState<number>();
  const [choseCat1, setChoseCat1] = useState<boolean>(false); // for product data_grid
  const [categories, setCategories] = useState<ProductCategoryGroup>({}); // for product data_grid
  const [sortSequence, setSortSequence] = useState<string[]>([]); // for product data_grid
  const [defaultSelectedCat1, setDefaultSelectedCat1] = useState<string[]>([]); // for product data_grid
  const [selectedCat1, setSelectedCat1] = useState<string[]>([]); // for product data_grid
  const [selectedCat2, setSelectedCat2] = useState<string[]>([]); // for product data_grid
  const [products, setProducts] = useState<ProductDataGridProduct[]>([]); // for product data_grid
  const [catOnes, setCatOnes] = useState<any[]>([]);
  const [selectedYear, setSelectedYear] = useState<string>(
    moment(new Date()).format("YYYY")
  );
  const [productPreviewIds, setProductPreviewIds] = useState<GridSelectionModel>([]);
  const [loading, setLoading] = useState(true);

  const url = new URL(window.location.href);

  const filterPostByYear = useCallback(async (year: string) => {
    try {
      setSelectedYear(year);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const generatePostDetailLink = useCallback((post: PostProps) => {
    if (post.disable_detail_view) {
      const assets = post.content?.filter((content: any) => {
        return content.__component === "page.asset-for-download";
      });
      if (assets && assets.length) {
        return assets[0]?.link;
      } else {
        return "#";
      }
    } else {
      const params = url.pathname === '/brands' ? post.code : post.id; // brand特殊處理
      return `${url.pathname}/${params}`;
    }
  }, [url.pathname]);

  // const getDetailViewProps = useCallback((post: PostProps) => {
  //   return {
  //     __component: 'common.image-with-frame',
  //     // className: classes.borderImgFrame,
  //     frame_width: gallery_options?.item_width ?? '100%',
  //     frame_height: gallery_options?.item_height ?? 'auto',
  //     image_url: post?.cover?.url,
  //     image_width: post?.cover?.width,
  //     image_height: post?.cover?.height,
  //   }
  // }, [url.pathname]);
  
  /**
   * @deprecated
   * using navigiation/searches/SearchBar component instead
   */
  // const Search = () => {
  //   return (
  //     <InputGroup className={classes.searchBar}>
  //       <FormControl
  //         arial-label="search keyword"
  //         value={searchKeyword}
  //         onChange={(event) => {
  //           setSearchKeyword(event.target.value);
  //           event.preventDefault();
  //         }}
  //       />
  //     </InputGroup>
  //   );
  // };

  // TODO: move to components
  const YearFilter = () => {
    const start = 2021;
    const startYear = moment("2021");
    const currYear = moment(new Date());
    const yearArray = [start];
    const yearDiff = currYear.diff(startYear, "years");

    for (let i = 0; i < yearDiff; i++) {
      yearArray.push(start + i);
    }
    return (
      <Container>
        <Row>
          {yearArray.map((year: any, index) => (
            <Col
              key={index}
              xs={{ span: 3 }}
              md={{ span: 1, offset: index === 0 ? 1 : 0 }}
              onClick={() => filterPostByYear(year)}
            >
              <Link
                to="#"
                className={
                  year === parseInt(selectedYear)
                    ? classes.activeYear
                    : classes.yearFilter
                }
              >
                {year}
              </Link>
            </Col>
          ))}
        </Row>
      </Container>
    );
  };

  const updateDisplayProducts = useCallback(() => {
    const keyword = searchKeyword.trim().toLowerCase();
    if (!choseCat1) {
      return;
    } else if (keyword === '' && selectedCat1.length === 0 && selectedCat2.length === 0 && choseCat1 && defaultSelectedCat1.length > 0) {
      setProducts(postsObject);
    } else if (keyword !== '' && (selectedCat1.length > 0 || selectedCat2.length > 0)) {
      const searchFilteredProducts = postsObject.filter((product: ProductDataGridProduct) => {
        return product.prod_no.toLowerCase().indexOf(keyword) > -1 ||
          product.prod_desc_web.toLowerCase().indexOf(keyword) > -1 ||
          product.spec.toLowerCase().indexOf(keyword) > -1 ||
          product.country_name.toLowerCase().indexOf(keyword) > -1 ||
          product.cat1.toLowerCase().indexOf(keyword) > -1 ||
          product.cat2.toLowerCase().indexOf(keyword) > -1 ||
          productPreviewIds.indexOf(product.id) > -1
      });
      const bothFilteredProducts = searchFilteredProducts.filter((product: ProductDataGridProduct) => {
        return selectedCat2.indexOf(product.cat1 + '-' + product.cat2) > -1 ||
          productPreviewIds.indexOf(product.id) > -1
      })
      setProducts(bothFilteredProducts);
    } else if (keyword !== '' && (selectedCat1.length === 0 || selectedCat2.length === 0)) {
      const searchFilteredProducts = postsObject.filter((product: ProductDataGridProduct) => {
        return product.prod_no.toLowerCase().indexOf(keyword) > -1 ||
          product.prod_desc_web.toLowerCase().indexOf(keyword) > -1 ||
          product.spec.toLowerCase().indexOf(keyword) > -1 ||
          product.country_name.toLowerCase().indexOf(keyword) > -1 ||
          product.cat1.toLowerCase().indexOf(keyword) > -1 ||
          product.cat2.toLowerCase().indexOf(keyword) > -1 ||
          productPreviewIds.indexOf(product.id) > -1
      });
      setProducts(searchFilteredProducts);
    } else if (
      (keyword === '' && (selectedCat1.length > 0 || selectedCat2.length > 0)) ||
      (choseCat1 && defaultSelectedCat1.length > 0)
    ) {
      const catFilteredProducts = postsObject.filter((product: ProductDataGridProduct) => {
        return selectedCat2.indexOf(product.cat1 + '-' + product.cat2) > -1 ||
          productPreviewIds.indexOf(product.id) > -1
      })
      setProducts(catFilteredProducts);
    } else {
      setProducts(postsObject);
    }
  }, [choseCat1, defaultSelectedCat1.length, postsObject, productPreviewIds, searchKeyword, selectedCat1.length, selectedCat2]);

  const getPostsData = useCallback(async () => {
    try {
      if (selectedCategories) {
        setLoading(true);
        let result: AxiosResponse<any>;
        const categoriesFilter = selectedCategories.reduce((accumulator, value, index) => index === 0 ? `categories_in=${value.toString()}` : accumulator + `&categories_in=${value.toString()}`, '');
        let baseQueryString = `${API_URL}/posts?title_contains=${keyword}&_sort=created_at:DESC&created_at_gte=${selectedYear}-01-01&created_at_lte=${selectedYear}-12-31&${categoriesFilter}&_limit=${items_per_page}&_start=${pageIndex * (items_per_page ?? 0)
          }`;

        switch (display) {
          case "text_with_side_images":
            switch (url.pathname) {
              case '/events':
                const eventsCatReq = await axiosInstance.get<any>(`${API_URL}/categories?type=PAGE`, {
                  params: { _locale: lang, name_contains: lang === 'en' ? 'events' : '活動' }
                });
                let eventQueryString = baseQueryString;
                if (eventsCatReq.data.length > 0) {
                  eventQueryString += `&categories_in=${eventsCatReq.data[0].id}`;
                  const eventsPostCountReq = await axiosInstance.get(`${API_URL}/posts/count`, {
                    params: { _locale: lang, categories_in: eventsCatReq.data[0].id}
                  });
                  setTotalPage(Math.ceil(eventsPostCountReq.data / (items_per_page ?? 1)));
                }
                result = await axiosInstance.get(eventQueryString, {
                  params: {_locale: lang }
                });
                break;
              default:
                result = await axiosInstance.get(baseQueryString, {
                  params: {_locale: lang }
                });
            }
            break;
          case "text_with_background":
            result = await axiosInstance.get(baseQueryString);
            break;
          case "gallery":
            switch (url.pathname) {
              case '/foodtalk':
                const foodtalkCatReq = await axiosInstance.get<any>(`${API_URL}/categories?type=PAGE&name_contains=food`);
                baseQueryString = `${API_URL}/posts?&_sort=title:DESC&title_contains=${keyword}&created_at_gte=${selectedYear}-01-01&created_at_lte=${selectedYear}-12-31&${categoriesFilter}&_limit=${items_per_page}&_start=${pageIndex * (items_per_page ?? 0)
                  }`;
                let foodQueryString = baseQueryString;
                if (foodtalkCatReq.data.length > 0) {
                  foodQueryString += `&categories_in=${foodtalkCatReq.data[0].id}`;
                }
                const foodPostCountReq = await axiosInstance.get(`${API_URL}/posts/count?categories_in=${foodtalkCatReq.data[0].id}`);
                setTotalPage(Math.ceil(foodPostCountReq.data / (items_per_page ?? 1)));
                result = await axiosInstance.get(foodQueryString);
                break;
              case '/recipes':
                baseQueryString = `${API_URL}/posts?&_sort=title:ASC&title_contains=${keyword}&created_at_gte=${selectedYear}-01-01&created_at_lte=${selectedYear}-12-31&${categoriesFilter}&_limit=${items_per_page}&_start=${pageIndex * (items_per_page ?? 0)}&_locale=${lang}`;
                let recipeQueryString = baseQueryString;
                let pageCategoryId = -1;
                //a category of type "Page" must exist to populate the filters on the left of the page.
                if (selectedCategories.length === 0) {
                  //if no selected category, we use the category that encapsulates posts RELATED to recipe
                  let recipeCatReq: AxiosResponse<any>;
                  switch(lang) {
                    case 'zh-Hant':
                      recipeCatReq = await axiosInstance.get<any>(`${API_URL}/categories?type=page&name_contains=所有&_locale=${lang}`);
                      break;
                    default:
                      recipeCatReq = await axiosInstance.get<any>(`${API_URL}/categories?type=page&name_contains=ALL`);
                  }
                  if (recipeCatReq.data.length > 0) {
                    pageCategoryId = recipeCatReq.data[0].id;
                    setDefaultRecipeCatId(pageCategoryId);
                    recipeQueryString += `&categories_in=${pageCategoryId}`;
                    setSelectedCategories([pageCategoryId]);
                    const recipePostCountReq = await axiosInstance.get(`${API_URL}/posts/count?categories_in=${pageCategoryId}&_locale=${lang}`);
                    setTotalPage(Math.ceil(recipePostCountReq.data / (items_per_page ?? 1)));
                  }
                } else {
                  recipeQueryString += `&categories_in=${selectedCategories[0]}`;
                  const recipePostCountReq = await axiosInstance.get(`${API_URL}/posts/count?categories_in=${selectedCategories[0]}&_locale=${lang}`);
                  setTotalPage(Math.ceil(recipePostCountReq.data / (items_per_page ?? 1)));
                }
                result = await axiosInstance.get(recipeQueryString);
                // if(selectedCategories.length > 0) {
                //   const recipePostCountReq = await axiosInstance.get(`${API_URL}/posts/count?categories_in=${selectedCategories[0]}`);
                //   setTotalPage(Math.ceil(recipePostCountReq.data / (items_per_page ?? 1)));
                // }
                break;
              case '/brands':
                const brandsPostCountReq = await axiosInstance.get(`${API_URL}/brands/count`, {
                  params: { _locale: lang, categories_in: selectedCategories[0] }
                });

                result = await axiosInstance.get(`${API_URL}/brands`, {
                  params: {
                    _locale: lang,
                    _limit: items_per_page,
                    _start: pageIndex * (items_per_page ?? 0),
                    _sort: 'name:ASC',
                    categories_in: selectedCategories[0],
                  },
                });
                setTotalPage(Math.ceil(brandsPostCountReq.data / (items_per_page ?? 1)));
                break;
              default:
                result = await axiosInstance.get(baseQueryString);
            }
            break;
          case "data_grid":
            // fetch products data
            // fetch from cms
            // result = await axiosInstance.get<Product[]>(`${API_URL}/products?_locale=${lang}&show=Y&_limit=2000&_sort=cat1:ASC,prod_no:ASC`);
            // fetch data from CDN
            result = await axiosInstance.get('https://res.cloudinary.com/dxsxlycjn/raw/upload/sku/sku.json', {
              headers: {
                "Cache-Control": "no-cache, no-store, must-revalidate",
                "Pragma": "no-cache",
                "Expires": "0",
              },
            });
            const data = result.data[lang].map((product: Product) => ({id: product.prod_no, ...product}));
            setProducts(data);

            // fetch categories data
            // if(!categories || Object.keys(categories).length === 0) {
            //   const catReq = await axiosInstance.get<ProductCategory[]>(`${API_URL}/product-categories?_limit=500&_locale=${lang}`);
            //   const catOnesReq = await axiosInstance.get<any>(`${API_URL}/product-category-1-s?_locale=${lang}`);
            //   setCatOnes(catOnesReq.data);
            //   const catRes = catReq.data;
            //   if (catRes.length > 0) {
            //     const orderedCat = _.orderBy(catRes, ['cat1', 'cat2']);
            //     const keyedCat = orderedCat.map((cat) => ({ key: cat.cat1 + '-' + cat.cat2, cat1: cat.cat1, cat2: cat.cat2 }));
            //     const groupedCat = _.groupBy(keyedCat, 'cat1');
            //     setCategories(groupedCat);
            //   }
            // }

            // if (!categories || Object.keys(categories).length === 0) {
            // const catReq = await axiosInstance.get<ProductCategory[]>(`${API_URL}/product-categories?_limit=500&_locale=${lang}`);
            let catOnesReq = await axiosInstance.get<any[]>(`${API_URL}/product-category-1-s?_locale=${lang}`);
            let catOnesRes = catOnesReq.data.map(catOne => {
              const { sequence, ...rest } = catOne;
              const order = Number.parseInt(sequence);
              return {
                sequence: order ? order : catOnesReq.data.length,
                ...rest,
              }
            });
            catOnesRes = _.orderBy(catOnesRes, ['sequence'], ['asc']);
            let catOnesArr = catOnesRes.map((categoryOne: { name: string; }) => categoryOne.name.toUpperCase());
            setSortSequence(catOnesArr);
            setCatOnes(catOnesRes);
            // change from cms to cdn
            // const catRes = result.data;
            const catRes = data;
            if (catRes.length > 0) {
              // const orderedCat = _.orderBy(catRes, ['cat1', 'cat2']);
              const keyedCat = catRes.map((cat: ProductCategory) => ({ key: cat.cat1 + '-' + cat.cat2, cat1: cat.cat1, cat2: cat.cat2 }));
              const uniquedCat = _.uniqBy(keyedCat, 'key');
              // custom sort key
              const orderedCat = _.orderBy(uniquedCat, [(cat: ProductCategory): ProductCategory => {
                const catOrderer = _.find(catOnesRes, o => o.name.toLowerCase() === cat.cat1!.toLowerCase());
                return catOrderer?.sequence ?? catRes.length;
              }, 'cat2']);
              const groupedCat = _.groupBy(orderedCat, 'cat1');
              // @ts-ignore: Unreachable code error
              setCategories(groupedCat);
            }
            // }

            /* api query method to query
            if (searchKeyword.length > 0) {
              result = await axiosInstance.get(`${API_URL}/products?_locale=${lang}&_q=${searchKeyword}`);
            } else if (selectedCategories.length > 0) {
              const cat2In = selectedCategories.reduce((accumulator, value, index) => (index === 0 ? `cat2_in=${value}` : accumulator + `&cat2_in=${value}`), '');
              result = await axiosInstance.get(`${API_URL}/products?_locale=${lang}&${cat2In}&_limit=1000`);
            } else {
              result = await axiosInstance.get(`${API_URL}/products?_locale=${lang}&show=Y&_limit=1000`);
            }
            */
            break;
          default:
            throw new Error("new dispaly type do not handled");
        }

        switch(display) {
          case "data_grid":
            const data = result.data[lang].map((product: Product) => ({id: product.prod_no, ...product}));
            setPostsObject(data);
            break;
          default:
            setPostsObject(result.data);
        }
      } else {
        setPostsObject(posts);
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  }, [selectedCategories, keyword, selectedYear, items_per_page, pageIndex, display, url.pathname, lang, posts]);

  const handleCategoryBodyFilterClick = async (event: React.MouseEvent<HTMLDivElement>, catId?: number) => {
    const categories: number[] = catId ? [catId] : [];
    setSelectedCategories(categories);
    setPageIndex(0);
  }
  
  /**
   * @deprecated
   */
  const onMultiSelectCategory = useCallback(
    async (category) => {
      if (selectedCat2.indexOf(category.id.toSring()) !== -1) {
        let newSelected = selectedCat2.filter(
          (categoryId) => categoryId !== category.id
        );
        // if empty categories list, set default
        if (newSelected.length === 0) {
          setSelectedCat2([category.id.toString()]);
        } else {
          setSelectedCat2(newSelected);
        }
      } else {
        selectedCat2.push(category.id);
        getPostsData();
      }
    },
    [getPostsData, selectedCat2]
  );

  const CategoryBody = useCallback((filter: any) => {
    let allTextZht = '所有';
    switch(url.pathname) {
      case '/recipes':
        allTextZht = '所有食譜';
        break;
      case '/brands':
        allTextZht = '所有品牌';
        break;
    }
    const categories: any = categoriesProp?.length &&  [{ id: defaultRecipeCatId, name: lang === 'en' ? 'ALL' : allTextZht }, ...categoriesProp];

    return (
      <Navbar className={classes.navbar} expand="sm">
        {/* <Navbar.Brand>Filter</Navbar.Brand> */}
        <Navbar.Toggle aria-controls="basic-navbar-nav"></Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className={classes.navWrapper}>
            <Nav.Link>
              {
                categories?.map((cat: any, j: number) => {
                  return (
                    <div key={j}>
                      {!filter.multi_select && (
                        <Col
                          className={
                            classes.categoryFilterItem +
                            (selectedCategories[0] === cat.id ? " active" : "")
                          }
                          onClick={(event: React.MouseEvent<HTMLDivElement>) => handleCategoryBodyFilterClick(event, cat.id)}
                        >
                          {cat.name}
                        </Col>
                      )}
                      {filter.multi_select && (
                        <Col>
                          <Checkbox
                            checked={selectedCat2.indexOf(cat.id) !== -1}
                            onChange={() => onMultiSelectCategory(cat)}
                            inputProps={{ "aria-label": cat.name }}
                          />
                          {cat.name}
                        </Col>
                      )}
                    </div>
                  );
                })
              }
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    )

  }, [categoriesProp, classes.categoryFilterItem, classes.navWrapper, classes.navbar, defaultRecipeCatId, lang, onMultiSelectCategory, selectedCat2, selectedCategories, url.pathname])

  /**
   * @deprecated
   */
  const CategoryFilter = useCallback(() => {

    return (
      <Container>
        {category_filter?.map((filter: any, index: number) => {
          return (
            <div key={index}>
              {filter?.name && (
                <Accordion
                  defaultActiveKey={'0'}
                >
                  <Accordion.Toggle
                    className={classes.accordionToggle}
                    as={Button}
                    variant="link"
                    eventKey={index.toString()}
                    onClick={(event: any) => {
                      console.log(event);
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faCaretRight}
                    />{" "}
                    {filter.name}
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey={index.toString()}>
                    <>{CategoryBody(filter)} </>
                  </Accordion.Collapse>
                </Accordion>
              )}
              {!filter?.name && CategoryBody(filter)}
            </div>
          );
        })}
      </Container>
    );
  }, [CategoryBody, category_filter, classes.accordionToggle]);


  // Product Detail related
  const handleKeywordSearch = (event: SearchEvent, inputKeyword: string) => {
    setSearchKeyword(inputKeyword);
  }

  // carousel
  const [carouselImages, setCarouselImages] = useState<ImageWithFrameProps[]>([]);

  // product flow
  const [productDetailData, setProductDetailData] = useState<GridRowData>({});
  const [showProductModal, setShowProductModal] = useState(false);
  const [showProductPdfPreviewModal, setShowProductPdfPreviewModal] = useState<boolean>(false);
  const [showProductContactModal, setShowProductContactModal] = useState<boolean>(false);
  const [showProductPdfDownloadModal, setShowProductPdfDownloadModal] = useState<boolean>(false);

  const fetchCaourselImages = useCallback(async (product: Product) => {
    const productEnglishRes = await axiosInstance.get<Product[]>(`${API_URL}/products?_locale=en&prod_no=${product.prod_no}`);
    const productEng = productEnglishRes.data[0];

    if (!productEng) {
      setCarouselImages([]);
      return;
    }

    const productImagesRes = await axiosInstance.get<UploadFile[]>(`${API_URL}/upload/files?name_contains=${productEng.company_cd}_${product.prod_no}_`);

    if (productImagesRes.data?.length > 0) {
      setCarouselImages(productImagesRes.data.map(image => (
        { image_url: image.url, image_width: image.width, image_height: image.height }
      )));
    } else {
      const brandImagesRes = await axiosInstance.get<BrandType[]>(`${API_URL}/brands?name=${productEng.brand}`);

      if (brandImagesRes.data?.length > 0 && brandImagesRes.data[0].logo) {
        setCarouselImages(brandImagesRes.data.map(brand => (
          { image_url: brand.logo?.url, image_width: brand.logo?.width, image_height: brand.logo?.height }
        )));
      } else {
        setCarouselImages([]);
      }
    }
  }, []);

  const requestHighlightSlides = useCallback(async() => {
    const highlightReq = await axiosInstance.get<HighlightSlide[]>(`${API_URL}/highlights`, {
      params: {
        _sort: 'sequence:ASC',
      },
    });

    if (highlightReq.data) {
      const orderedHighlights = _.orderBy(highlightReq.data, ['sequence']);
      const highlightSlides = orderedHighlights.map(
        highlightSlide => (
          {
            id: highlightSlide?.id,
            frame_width: '100%',
            frame_height: 'content_width',
            image_url: highlightSlide?.image?.url,
            image_width: highlightSlide?.image?.width,
            image_height: highlightSlide?.image?.height,
          }
        )
      )

      setHighlightSlides(highlightSlides);
    }
  }, []);

  const handleProductDataGridCheckboxChange = (model: GridSelectionModel) => {
    setProductPreviewIds(model);
  }

  const handleProductDataGridRowClick = (param: GridRowParams, event: MuiEvent<React.SyntheticEvent<Element, Event>>) => {
    setShowProductModal(true);
    setProductDetailData(param.row);
    fetchCaourselImages(param.row);
  }

  const handleProductDetailModalClose = () => {
    setShowProductModal(false);
  }

  const handleProductCategory1Clicked = (event: React.MouseEvent<HTMLDivElement>, cat1Index: number, cat1: string, cat2s: ProductCategory[]) => {
    setChoseCat1(true);
    setDefaultSelectedCat1([cat1]);
    setSelectedCat2(cat2s.map(cat2 => cat2.key!));
  }

  const handleProductCategory1Checked = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean, cat1Checked: Map<string, boolean>) => {
    cat1Checked.forEach((value, key) => {
      if (value) {
        selectedCat1.push(key);
      } else {
        let cat1Loc = selectedCat1.indexOf(key);

        while (cat1Loc > -1) {
          selectedCat1.splice(cat1Loc, 1);
          cat1Loc = selectedCat1.indexOf(key);
        }
      }
    })

    setSelectedCat1(_.cloneDeep(selectedCat1));
  }

  const handleProductCategory2Checked = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean, cat2Checked: Map<string, boolean>) => {
    cat2Checked.forEach((value, key) => {
      if (value) {
        selectedCat2.push(key);
      } else {
        let cat2Loc = selectedCat2.indexOf(key);

        while (cat2Loc > -1) {
          selectedCat2.splice(cat2Loc, 1);
          cat2Loc = selectedCat2.indexOf(key);
        }
      }
    })

    setSelectedCat2(_.cloneDeep(selectedCat2));
  }

  const handleProductPreviewButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (productPreviewIds.length > 0) {
      setShowProductPdfPreviewModal(true);
    }
  }

  const handleProductPdfPreviewModalDownload = (event: React.MouseEvent<HTMLButtonElement>) => {
    setShowProductPdfPreviewModal(false);
    setShowProductContactModal(true);
  }

  const handleProductPdfPreviewModalClose = () => {
    setShowProductPdfPreviewModal(false);
  }

  const handleProductContactModalDownload = async (event: React.MouseEvent<HTMLButtonElement>, data: ContactInformation) => {
    const nature_of_business_option = data.nature_of_business[0];
    if (data.name === '' || data.company === '' || nature_of_business_option === '' || data.title_or_dept === '' || data.phone === '') {
      alert('Please fill in all necessary information(red asterix)');
      return;
    } else if (!isValidEmail(data.email)) {
      alert('Wrong Email');
      return;
    }

    try {
      const sendData = _.omit(data, ['nature_of_business']);
      sendData['nature_of_business'] = nature_of_business_option;

      const selectedProducts = products.filter(product => productPreviewIds.indexOf(product.id) > -1);
      const filteredProducts = selectedProducts.map(product => _.pick(product, ['prod_no', 'prod_desc_web', 'spec', 'country_name']));
      sendData['products'] = filteredProducts;

      await axiosInstance.post(`${API_URL}/product-contact-informations`, sendData);
    } catch(err) {
      console.error(err);
    }

    setShowProductContactModal(false);
    setShowProductPdfDownloadModal(true);
  }

  const handleProductContactModalClose = () => {
    setShowProductContactModal(false);
  }

  const handleProductDownloadModalDownload = (event: React.MouseEvent<HTMLButtonElement>) => {
    // removed from UI
  }

  const handleProductDownloadModalClose = () => {
    setShowProductPdfDownloadModal(false);
  }

  const jumpPages = useCallback(async (pageNumber: number) => {
    setPageIndex(pageNumber);
  }, []);

  const renderGalleryPosts = useCallback((): ReactNode => {
    const colClassName = `${classes.galleryCol}${url.pathname === '/brands' ? ' ' + classes.brandCol : ''}`
    return postsObject.map((post: PostProps, i: number) => {
      return (
        <Col
          key={i}
          className={colClassName}
          xs={{ span: 12, offset: 0 }}
          sm={{ span: 6, offset: 0 }}
          md={{ span: gallery_options && gallery_options.items_per_row > 0 ? Math.floor(12 / gallery_options.items_per_row) : 3, offset: 0 }}
        >
          {post.disable_detail_view ? (
            <a target="__blank" href={generatePostDetailLink(post)}>
              <ContentBuilder
                id={i}
                key={i}
                __component={"common.image-with-frame-and-text"}
                frame_width={gallery_options && gallery_options.item_width ? gallery_options.item_width : '100%'}
                frame_height={gallery_options && gallery_options.item_height ? gallery_options.item_height : 'auto'}
                image_url={post.cover?.url}
                image_width={post.cover?.width}
                image_height={post.cover?.height}
                text={post.title}
              />
            </a>
          ) : (
            <Link
              className={classes.postLink}
              key={i}
              to={generatePostDetailLink(post)}
            >
              <ContentBuilder
                id={i}
                key={i}
                __component={"common.image-with-frame"}
                className={classes.imageWithFrame}
                frame_width={gallery_options && gallery_options.item_width ? gallery_options.item_width : '100%'}
                frame_height={gallery_options && gallery_options.item_height ? gallery_options.item_height : 'auto'}
                image_url={post.cover?.url}
                image_width={post.cover?.width}
                image_height={post.cover?.height}
              />
            </Link>
          )}

        </Col>
      );
    })
  }, [classes.galleryCol, classes.postLink, classes.brandCol, classes.imageWithFrame, url.pathname, gallery_options, generatePostDetailLink, postsObject]);

  useEffect(() => {
    getPostsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategories, pageIndex]);

  useEffect(() => {
    if (display === 'data_grid') {
      updateDisplayProducts();
      requestHighlightSlides();
    }
  }, [display, postsObject, productPreviewIds, searchKeyword, choseCat1, defaultSelectedCat1, selectedCat1, selectedCat2, updateDisplayProducts, requestHighlightSlides]);

  useEffect(() => {
    if (display === 'gallery') {
      renderGalleryPosts();
      CategoryFilter();
    }
  }, [display, selectedCategories, renderGalleryPosts, CategoryFilter]);

  const [showHighlightSlideModal, setShowHighlightSlideModal] = useState<boolean>(false);
  const [highlightSlides, setHighlightSlides] = useState<ImageWithFrameAndTextProps[]>([]);
  const [highlightSlide, setHighlightSlide] = useState<ImageWithFrameAndTextProps>({ frame_width: '100%', frame_height: 'content' });
  const handleHighlightSlideClick = (event: React.MouseEvent<HTMLDivElement>, slide: ImageWithFrameAndTextProps) => {
    setShowHighlightSlideModal(true);
    setHighlightSlide(slide);
  }
  const handleHighlightSlideClose = () => {
    setShowHighlightSlideModal(false);
  }

  // show category before product data grid
  const renderProductCategories = useCallback((): ReactNode => {
    if (!postsObject || postsObject.length === 0) {
      return <Container>No results found.</Container>;
    }
    return <>
      {
        catOnes.map((catOne, index) => {
          const catOneKey = catOne.name.toUpperCase();
          return <Col
            key={index}
            className={classes.categoryCol}
            xs={{ span: 6, offset: 0 }}
            sm={{ span: 6, offset: 0 }}
            md={{ span: gallery_options && gallery_options.items_per_row > 0 ? Math.floor(12 / gallery_options.items_per_row) : 4, offset: 0 }}
          >
            <ImageWithFrameAndText
              frame_width={gallery_options ? gallery_options.item_width : '100%'}
              frame_height={gallery_options ? gallery_options.item_height : 'frame_width'}
              image_url={catOne?.photo?.url}
              image_width={100}
              image_height={100}
              text={catOne.name}
              textAlign={'center'}
              onClick={(event) => handleProductCategory1Clicked(event, index, catOneKey, categories[catOneKey] ?? [])}
            />
          </Col>
        })
      }
    </>
  }, [catOnes, categories, classes.categoryCol, gallery_options, postsObject]);

  const renderDisplay = (): ReactNode => {
    if (!postsObject || postsObject.length === 0) {
      return <Container>No results found.</Container>;
    }
    switch (display) {
      case "text_with_side_images":
        return postsObject.map((post: PostProps, i: number) => {
          return (
            <Link
              className={classes.postLink}
              key={i}
              to={`${url.pathname}/${post.id}`}
            >
              <ContentBuilder
                id={i}
                key={i}
                __component={"page.angliss-imagepost"}
                image_alignment={i % 2 === 0 ? "left" : "right"}
                page_fullwidth={false}
                image={post.cover}
                title={post.title}
                excerpt={post.excerpt}
                style_excerpt={ url.pathname === '/events' ? classes.eventExcerptStyle : ''}
              />
            </Link>
          );
        });
      case "text_with_background":
        return postsObject.map((post: PostProps, i: number) => {
          return (
            <Link
              className={classes.postLink}
              key={i}
              to={`${url.pathname}/${post.id}`}
            >
              <ContentBuilder
                id={i}
                key={i}
                __component={"page.text-with-background"}
                alignment={post.alignment}
                cover={post.cover}
                title={post.title}
                excerpt={post.excerpt}
                isSingle={false}
                description={post.description}
              />
            </Link>
          );
        });
      case "gallery":
        return (
          <Container>
            <Row>
              {
                renderGalleryPosts()
              }
            </Row>
          </Container>
        );
      case "data_grid":
        return <div className={classes.dataGrid}>
          <ProductPreviewButton lang={lang} selected={productPreviewIds.length} onClick={handleProductPreviewButtonClick} />
          <ProductDataGrid lang={lang} selected={productPreviewIds} data={products} pageSize={items_per_page} onCheckboxChange={handleProductDataGridCheckboxChange} onRowClick={handleProductDataGridRowClick} />
          {
            showProductModal &&
            <ProductModal
              lang={lang}
              slides={carouselImages}
              onCloseClick={handleProductDetailModalClose}
              {...productDetailData}
            />
          }
          {
            showProductPdfPreviewModal &&
            <ProductPdfPreviewModal
              lang={lang}
              products={products}
              selected={productPreviewIds}
              onDownloadClick={handleProductPdfPreviewModalDownload}
              onCloseClick={handleProductPdfPreviewModalClose}
            />
          }
          {
            showProductContactModal &&
            <ProductContactModal
              lang={lang}
              onDownloadClick={handleProductContactModalDownload}
              onCloseClick={handleProductContactModalClose}
            />
          }
          {
            showProductPdfDownloadModal &&
            <ProductPdfDownloadModal
              lang={lang}
              products={
                products.filter(product => productPreviewIds.indexOf(product.id) > -1)
                  .map(product => _.pick(product, ['id', 'prod_no', 'prod_desc_web', 'spec', 'country_name']))
              }
              onDownloadClick={handleProductDownloadModalDownload}
              onCloseClick={handleProductDownloadModalClose}
            />
          }
        </div>;
      default:
        return <></>;
    }
  };

  return (
    <Page className={classes.root} title="Angliss">
      {loading && <Spinner />}
      {!loading && year_filter?.enable && <YearFilter />}
      <Container className={classes.postContainer}>
        <Row className={classes.row}>
          {
            !loading && category_filter && category_filter?.length > 0 && (
              <Col md={3}>
                {enable_search && <SearchBar searchKeyword={searchKeyword} onSearch={handleKeywordSearch} />}
                {CategoryFilter()}
              </Col>
            )
          }
          {
            !loading && display === 'data_grid' && !choseCat1 &&
            renderProductCategories()
          }
          {
            !loading && display === 'data_grid' &&
            choseCat1 &&
            (
              <Col md={3}>
                {enable_search && <SearchBar searchKeyword={searchKeyword} onSearch={handleKeywordSearch} />}
                {
                  <ProductCategoryBuilder
                    data={categories}
                    sortSequence={sortSequence}
                    defaultSelectedCat1={defaultSelectedCat1}
                    selectedCat1={selectedCat1}
                    selectedCat2={selectedCat2}
                    onCat1CheckboxClicked={handleProductCategory1Checked}
                    onCat2CheckboxClicked={handleProductCategory2Checked}
                  />
                }
              </Col>
            )
          }
          {
            !loading && display === 'data_grid' && choseCat1 &&
            <Col md={9}>
              {renderDisplay()}
            </Col>
          }
          {!loading && display !== 'data_grid' && (
            <Col md={category_filter?.length ? 9 : 12}>
              {renderDisplay()}
              {/* TODO: move to components */
                totalPage > 1 && (
                  <Container>
                    <Row className="justify-content-center">
                      <Pagination className={classes.pagingLink}>
                        <Pagination.First
                          disabled={pageIndex === 0}
                          onClick={() => jumpPages(0)}
                        />
                        <Pagination.Prev
                          disabled={pageIndex === 0}
                          onClick={() => jumpPages(pageIndex - 1)}
                        />
                        {totalPage < 10 &&
                          [...Array(totalPage)].map((_, index: number) => (
                            <Pagination.Item
                              className={index === pageIndex ? classes.activePage : ""}
                              active={index === pageIndex}
                              onClick={() => jumpPages(index)}
                            >
                              {index + 1}
                            </Pagination.Item>
                          ))}
                        {totalPage >= 10 && (
                          <>
                            {pageIndex > 1 && <Pagination.Ellipsis />}
                            {pageIndex > 0 && (
                              <Pagination.Item
                                onClick={() => jumpPages(pageIndex - 1)}
                              >
                                {pageIndex}
                              </Pagination.Item>
                            )}
                            <Pagination.Item
                              className={classes.activePage}
                              active
                              onClick={() => jumpPages(pageIndex)}
                            >
                              {pageIndex + 1}
                            </Pagination.Item>
                            {pageIndex < totalPage - 1 && (
                              <Pagination.Item
                                onClick={() => jumpPages(pageIndex + 1)}
                              >
                                {pageIndex + 2}
                              </Pagination.Item>
                            )}
                            {pageIndex < totalPage - 2 && <Pagination.Ellipsis />}
                          </>
                        )}
                        <Pagination.Next
                          disabled={pageIndex === totalPage - 1}
                          onClick={() => jumpPages(pageIndex + 1)}
                        />
                        <Pagination.Last
                          disabled={pageIndex === totalPage - 1}
                          onClick={() => jumpPages(totalPage - 1)}
                        />
                      </Pagination>
                    </Row>
                  </Container>
                )}
            </Col>
          )}
        </Row>
      </Container>
      <Container>
        {
          !loading && display === 'data_grid' && !choseCat1 &&
          <Highlight
            slides={highlightSlides}
            onSlideClick={handleHighlightSlideClick}
          />
        }
        {
          !loading && showHighlightSlideModal &&
          <HighlightSlideModal
            slides={highlightSlides}
            slide={highlightSlide}
            onCloseClick={handleHighlightSlideClose}
          />
        }
      </Container>
    </Page>
  );
};

export default Posts;
